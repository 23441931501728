import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getHomeMainService } from "../actions/homeAction";
import { useEffect, useState } from "react";
import MapSearch from "./MapSearch";

const HomeServices = () => {
  const { t } = useTranslation();
  const countryId = localStorage.getItem("userCountryId");
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const { services} = getAllServicesState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const [serviceID, setServiceID] = useState("");
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";

  useEffect(() => {
    const countryId = localStorage.getItem("userCountryId");
    let listMainUrl = baseUrl + `/home/dashboard?country_id=${countryId}`;

    if (countryId) {
      dispatch(getHomeMainService(listMainUrl));
    }
  }, [countryId]);

  useEffect(() => {
    const homeCleaningService = services?.flatMap(service =>
      service.serviceTypes
    ).find(type => type.name === 'Home Cleaning');

    if (homeCleaningService) {
      // navigate("/service/"+homeCleaningService._id)
     setServiceID(homeCleaningService._id)
    }
  }, [services])


  return (
    <div className="homeServices">
      <div className="container">
        <div className="homeservice-para1">
          <h1>We turn Houses into Home</h1>
          <br /> <br />
        </div>
        <div style={{ maxWidth: '37rem' }}>
          <MapSearch short={false}/>
        </div>
        <div className="servicesSection">
          <Link className="d-flex home-cleaning"  to={`/service/${serviceID}`}>
            <div className="serviceIcon" style={{backgroundColor: '#8cc43c'}}>
              <img src="./images/cleaning.png" 
              title="Home Cleaning" 
              alt="Home Cleaning" 
              style={{ marginLeft: '25%', marginTop: '25%', width: '35%' }}
              />
            </div>
            <div className="service-Link">
              Home Cleaning
            </div>
          </Link>
          <Link className="d-flex all-services-block" to="/services" >
            <div className="serviceIcon" style={{ backgroundColor: '#44d4bc' }}>
            <img src="./images/all-services.png" 
              title="All Services" 
              alt="All Services" 
              style={{ marginLeft: '27%', marginTop: '20%', width: '35%' }}
            />
            </div>
            <div className="service-Link">
              All Services
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeServices;
