import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHomeMainService } from "../../actions/homeAction";
import { useTranslation } from "react-i18next";

const HomeAllServices = () => {
  const { t } = useTranslation();
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const { loading, services, success } = getAllServicesState;
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const currentLanguage = localStorage.getItem("currentLanguage")
  ? localStorage.getItem("currentLanguage")
  : "en";

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  useEffect(() => {
    // Fetch categories and their respective services
    const countryID = localStorage.getItem("userCountryId");
    let listMainUrl =
      baseUrl +
      `/home/dashboard?country_id=${localStorage.getItem("userCountryId")}`;
    if (countryID) {
      dispatch(getHomeMainService(listMainUrl));
    }
  }, []);

  return (
    <>
      <div className="container">
        <div
          className="home-all-services"
        >
          {services?.slice(0, 9).map((icon, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  overflow: "hidden", // Ensures the image is cropped to a circle
                  border: "1px solid grey", // Border around the circle
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "5px",
                }}
              >
                {/* Replace with an <img src={icon.image} alt={icon.name} /> for image icons */}
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", // Makes the image cover the circular area
                  }}
                  src={icon.icon}
                  alt={currentLanguage==="en" ? icon.name : icon.name_arabic}
                  title={currentLanguage==="en" ? icon.name : icon.name_arabic}
                />
              </div>
              <span style={{ fontSize: '14px' }}>{currentLanguage==="en" ? icon.name : icon.name_arabic}</span>
            </div>
          ))}
        </div>
        <div>
          {services?.slice(0, 3).map((icon, index) => (
            <div>
              <h2 style={{ marginTop: "5%" }}>{currentLanguage==="en" ? icon.name : icon.name_arabic}</h2>
              <div
                className="services-grid home-services"
              >
                {icon.serviceTypes?.map((service, idx) => (
                  <Link to={`/service/${service.id}`} key={service.id}>
                    <img
                      src={service.icon}
                      alt={currentLanguage==="en" ? service.name : service.name_arabic}
                      title={currentLanguage==="en" ? service.name : service.name_arabic}
                      className="card-img"
                    />
                    <div className="card-body">
                      <h5 className="card-title">{currentLanguage==="en" ? service.name : service.name_arabic}</h5>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </div>
        {!showMore && (
          <button
            className="btn btn-primary left-space"
            style={{ width: "100%" }}
            onClick={() => {
              setShowMore(true);
            }}
          >
            Show More
          </button>
        )}
        {showMore && (
          <div>
            {services?.slice(3).map((icon, index) => (
              <div>
                <h2 style={{ marginTop: "5%" }}>{currentLanguage==="en" ? icon.name : icon.name_arabic}</h2>
                <div
                  className="services-grid"
                  style={{ gridTemplateColumns: "repeat(5, 1fr)" }}
                >
                  {icon.serviceTypes?.map((service, idx) => (
                    <Link to={`/service/${service.id}`}>
                      <img
                        src={service.icon}
                        alt={currentLanguage==="en" ? service.name : service.name_arabic}
                        title={currentLanguage==="en" ? service.name : service.name_arabic}
                        className="card-img"
                      />
                      <div className="card-body">
                        <h5 className="card-title">{currentLanguage==="en" ? service.name : service.name_arabic}</h5>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default HomeAllServices;
