import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./Dropdown.css";
import { useSelector, useDispatch } from "react-redux";
import { getHomeMainService } from "../actions/homeAction";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import Loader from "./Loader";

const Dropdown = () => {
  const { t } = useTranslation();
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const { services, loading } = getAllServicesState;
  const dispatch = useDispatch();
  const currentLanguage = localStorage.getItem("currentLanguage")
  ? localStorage.getItem("currentLanguage")
  : "en";

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;

  useEffect(() => {
    // Fetch categories and their respective services
    const countryID = localStorage.getItem("userCountryId");
    let listMainUrl =
      baseUrl +
      `/home/dashboard?country_id=${localStorage.getItem("userCountryId")}`;
    if (countryID) {
      dispatch(getHomeMainService(listMainUrl));
    }
  }, []);

  if (loading && !services) {
    return (
      <Loader />
    )
  }
  return (
    <div className="dropdown">
      <div className="dropdown-category navbar-brand p-0 d-none d-md-block">
        <Link to="/services">All Services <FontAwesomeIcon icon={faAngleUp} className="down-icon-show" style={{ marginLeft: "2px" }} />
        <FontAwesomeIcon icon={faAngleDown} className="up-icon-show" style={{ marginLeft: "2px" }} />
         </Link>
        <div className="dropdown-menu">
          <div className="d-flex" style={{ marginTop: '2%', marginBottom: '2%', flexWrap: 'row-wrap' }}>

            {services?.slice(0, 3).map((service, i) => {
              return (
                <ul>
                  <li style={{ fontSize: '14px', color: '#9e6dc8', fontWeight: 'bold', cursor:'text' }} key={"service_name"}>{currentLanguage==="en" ? service.name : service.name_arabic}</li>
                  {service.serviceTypes?.map((service, i) => {
                    return (
                      <li className="li-services" style={{ paddingLeft: '5px' }} key={i}>
                        <Link
                          style={{ fontSize: '14px' }}
                          to={`/service/${service.id}`}
                        >{currentLanguage==="en" ? service.name : service.name_arabic}</Link>
                      </li>
                    )
                  })}
                </ul>
              )

            })}


          </div>
          <Link
            to="/services"
            className="booking-continue-button btn-continue initial-height btn btn-primary"
            style={{ width: "30%", marginLeft: '35%' }}
          >
            See All Services
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
