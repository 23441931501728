import React, { useRef, useEffect, useState } from "react";
import { MdMyLocation } from "react-icons/md";
import { fromLatLng } from "react-geocode";

function MapSearch({
  short = false,
  displayLocationIcon = true,
  searchedLocation = "",
}) {
  const autocompleteInputRef = useRef(null);
  const autocompleteService = useRef(null);
  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem("userCountryCode"));
  const [input, setInput] = useState(localStorage.getItem("address"));

  useEffect(() => {
    // Ensure the Google Maps API is loaded
    if (!window.google || !window.google.maps) return;

    // Initialize the autocomplete service
    autocompleteService.current = new window.google.maps.places.Autocomplete(
      autocompleteInputRef.current,
      {
        componentRestrictions: { country: selectedCountry }, // Default country restriction
        fields: ["address_components", "geometry", "icon", "name"], // Specify fields
      }
    );

      // Add a listener for place selection
      autocompleteService.current.addListener("place_changed", () => {
        const place = autocompleteService.current.getPlace();
        if (place.geometry && place.name) {
          const { lat, lng } = place.geometry.location.toJSON();
  
          // Store the latitude and longitude in localStorage
          localStorage.setItem("mapLatitude", lat);
          localStorage.setItem("mapLongitude", lng);
          localStorage.setItem("address", place.name);
          setInput(place.name)
          window.location.reload();
        } else {
          console.warn("No geometry available for this place.");
          window.location.reload();
        }
      });
  }, [selectedCountry]);


  const setCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // setMarker({lat: latitude, lng: longitude});
        localStorage.setItem("latitude", latitude);
        localStorage.setItem("longitude", longitude);
        const lat = latitude;
        const lng = longitude;
        fromLatLng(lat, lng).then(
          (response) => {
            const address = response.results[0].formatted_address;
            const addressArray = response.results[0].address_components;
            localStorage.setItem("address", address)
            const countryComponent = addressArray.find((component) =>
              component.types.includes("country")
            );
            localStorage.setItem("userCountryCode", countryComponent.short_name);
          },
          (error) => {
            console.error(error);
          }
        );
      },
      (error) => {
        console.error("Error getting current location:", error);
      }
    );

  }

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    setInput(inputText);
  }

  return (
    <div className="container">
      <div
        style={{
          position: "relative",
          border: "1px solid black",
          borderRadius: short ? "0px" : "30px",
          backgroundColor: "white",
          display: "flex",
          padding: "7px",
          marginLeft: "-10px",
        }}
      >
        <img
          src="/images/pin-point.png"
          alt="pin-point"
          title="Search Location"
          style={{ height: "25px", marginTop: '1%' }} />
        <input
          className="search-container"
          type="text"
          ref={autocompleteInputRef}
          placeholder={short ? "" : "Where would you like us to serve you?"}
          style={{ fontSize: '16px', paddingLeft: '5px !important' }}
          value={input}
          onChange={handleInputChange}
        />
        {displayLocationIcon && (
          <button
            style={{
              width: short ? "10%" : "50%",
              backgroundColor: "#9e6dc8",
              border: "none",
              outline: "none",
              borderRadius: "30px",
              marginLeft: "2%",
            }}
            onClick={setCurrentLocation}
          >
            <MdMyLocation /> {short ? "" : "Set My Location"}
          </button>
        )}
      </div>
    </div>
  );
}

export default MapSearch;
